import { useMemo, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { cacheOptions } from './cache-options';
// const cacheOptions = {};
// import { useRouter } from 'next/router';
import { createApolloClient } from './apollo-client';

export function createGraphQLClient(sellerId, getToken) {
  // if (!process.env.NEXT_PUBLIC_GRAPHQL_SERVER) throw new Error('Missing config NEXT_PUBLIC_GRAPHQL_SERVER');

  let server = process.env.NEXT_PUBLIC_GRAPHQL_SERVER || 'http://localhost:8888';
  console.info('Create Apollo Client - connect to', server, sellerId);
  if (!sellerId) throw new Error('Invalid seller Id');
  let headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-RP-SELLER': sellerId
  };

  return createApolloClient(server, { headers, cacheOptions, getToken });
}

let apolloClients = {};
export function initializeApollo(getToken, initialState = null, sellerId = 'root') {
  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = apolloClients[sellerId] ?? createGraphQLClient(sellerId, getToken);

  if (initialState) {
    const existingCache = _apolloClient.extract();
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;

  if (!apolloClients[sellerId]) apolloClients[sellerId] = _apolloClient;
  return _apolloClient;
}

export function useApollo(initialState, sellerId = 'root') {
  // const { getAccessTokenSilently, error } = useAuth0();

  // const getToken = useCallback(async ()=>{
  //   const fetchToken = async ()=>{
  //     if (!process.env.NEXT_PUBLIC_GRAPHQL_SERVER) throw new Error('Unknown NEXT_PUBLIC_GRAPHQL_SERVER');
  //     try {
  //       let url = new URL(process.env.NEXT_PUBLIC_GRAPHQL_SERVER);
  //       const accessToken = await getAccessTokenSilently({
  //         audience: url.origin,
  //         scope: 'read:orders'
  //       });
  //       console.log('OLD Access Token', accessToken);
  //       localStorage.setItem('token', accessToken);
  //       return accessToken;
  //     } catch (e) {
  //       console.error('Cannot get access token', e);
  //       return null;
  //     }
  //   };
  //   let token = localStorage.getItem('token');
  //   if (token) {
  //     const jwt = JSON.parse(atob(token.split('.')[1]));
  //     let expiry = new Date(jwt.exp * 1000);
  //     if (expiry - Date.now() < 0) {
  //       return fetchToken();
  //       // return null;
  //     }
  //     return token;
  //   }
  //   return fetchToken();
  // }, [getAccessTokenSilently]);
  const getToken = async () => null;
  const store = useMemo(() => {
    return initializeApollo(getToken, initialState, sellerId);
  }, [getToken, initialState, sellerId]);

  // if (error) {
  //   console.error('useAuth0 error', error);
  // }
  return store;
}
