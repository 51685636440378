'use client';

import { useUser as useAuth0User } from '@auth0/nextjs-auth0/client';
import { Button, LinearProgress } from '@mui/joy';
import { UserProvider as Auth0UserProvider } from '@auth0/nextjs-auth0/client';

export function LoggedOut() {
  const handleClick = () => {
    window.location.href = '/api/auth/login';
  };

  return <div className="flex h-screen flex-col items-center justify-center">
    <h1 className="text-2xl font-bold">You are not logged in.</h1>
    <p className="text-lg">Please log in to continue.</p>
    <div className="my-4"><Button size="lg" onClick={handleClick}>Log In</Button></div>
  </div>;
}

export function useUser() {
  const auth0User = useAuth0User();

  const { user } = auth0User;
  if (user && user.name) {
    if (user.name === 'steve@ninekilobits.com'
      || user.name === 'viv@rileyprint.com'
      || user.name === 'dev@ninekilobits.com') {
      user.group = 'admin';
    }
  }
  return { ...auth0User, user };
}

export function CheckAuth({ children }) {
  const { user, isLoading } = useUser();
  if (isLoading) {
    return <div>
      <LinearProgress />
      <div className="mx-auto my-4 text-center">Verifying user...</div>
    </div>;
  }
  if (!user) {
    return <LoggedOut/>;
  }
  return children;
}

export function UserProvider({ children, disabled }) {
  var user;
  if (disabled) {
    user = {
      name: 'dev@ninekilobits.com'
    };
  }
  return <Auth0UserProvider user={user}>
    <CheckAuth>
      {children}
    </CheckAuth>
  </Auth0UserProvider>;
}
