import { ApolloProvider } from '@apollo/client';
import { useApollo } from '@/adapters/oms-client';

export function Auth0ApolloProvider({ children, sellerId = null, initialApolloState }) {
  const apolloClient = useApollo(initialApolloState, sellerId);
  // const { isAuthenticated, isLoading } = useAuth0();
  // const { user, error, isLoading } = useUser();
  // if (isLoading) return <div className="text-center my-20">Loading Apollo Client...</div>;
  // if (!isAuthenticated) {
  //   return <LoginScreen/>;
  // }
  if (!apolloClient) {
    return <div className="my-20 text-center">Authenticating...</div>;
  }
  return <ApolloProvider client={apolloClient}>
    {children}
  </ApolloProvider>;
}

export function LogoutButton({ className }) {
  return (
    // eslint-disable-next-line @next/next/no-html-link-for-pages
    <a className={className} href="/api/auth/logout">
      Log Out
    </a>
  );
}
