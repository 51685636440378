import { useRouter } from 'next/router';
import { Auth0Provider } from '@auth0/auth0-react';
import { UserProvider } from '@/_components/auth0';
import '../styles/globals.css';

import { Auth0ApolloProvider } from '@/components/auth';
import { Alerts } from '@/components/alerts';

export default function MyApp({ Component, pageProps }) {
  // console.log('pageProps', pageProps)

  let router = useRouter();
  let sellerId = router.query.seller || 'root';
  let apiServer = new URL(process.env.NEXT_PUBLIC_GRAPHQL_SERVER || 'http://localhost:8888');

  return <UserProvider>
    <Auth0ApolloProvider
      initialApolloState={pageProps.initialApolloState}
      sellerId={sellerId}>
      <Component {...pageProps} />
    </Auth0ApolloProvider>
    <Alerts/>
  </UserProvider>;


  return <Auth0Provider
    domain="9kb.us.auth0.com"
    clientId="NaMrvdDx1s0bWZgSg5ZWPiRPKavzlFiI"
    authorizationParams={{
      audience: apiServer.origin,
      redirect_uri: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI || 'https://work.9kb.dev',
      useRefreshTokens: true,
      cacheLocation: 'localstorage'
    }}>
    <Auth0ApolloProvider
      initialApolloState={pageProps.initialApolloState}
      sellerId={sellerId}>
      <Component {...pageProps} />
    </Auth0ApolloProvider>
    <Alerts/>
  </Auth0Provider>;
}
