import {
  ApolloClient, InMemoryCache, ApolloLink, HttpLink
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';

export function createApolloClient(uri, { headers: addHeaders, cacheOptions, getToken }) {
  // let addHeaders = headers;
  const authLink = setContext(async (_, { headers }) => {
    // let token = await getToken();
    return {
      headers: {
        ...headers,
        ...addHeaders,
        // authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        console.error(
          `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`
        );
        Sentry.captureException(error);
      });
    }
    if (networkError) {
      if (networkError.name === 'ServerError' && networkError.statusCode === 401) {
        console.warn('401 Unauthenticated');
      }
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const httpLink = new HttpLink({
    uri
  });

  const defaultOptions = {
    query: {
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  };

  return new ApolloClient({
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(cacheOptions),
    defaultOptions
  });
}
